export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}
export enum SKIN {
  DEFAULT = 'primary',
  BLUE = 'blue',
}
export interface AppContextState {
  isLogin: boolean | null;
  token: string | null;
  locale: string;
  theme: THEME;
  skin: SKIN;
}

import { LANG } from '@/core/i18n';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// 格式化成交量
const formatVolume = (num: string) => {
  if (!num) return;
  const base = 1000000;
  if (Number(num) > base) {
    return num.div(base)?.toFormat(0) + 'M';
  } else {
    return num?.toFormat(0);
  }
};
/**
 * 将传入的字符串或数字值保留两位小数并返回一个数字。如果传入的是空字符串或者未定义的值，则返回数字0
 * @param value
 * @returns number
 */
function truncateToTwoDecimals(value: string | number): number {
  if (value === '' || !value) return 0;
  const truncated = Math.trunc(Number(value) * 100) / 100;
  const str = String(value);
  if (str.includes('.') && str.split('.')[1].length > 2) {
    return truncated;
  } else {
    return Number(truncated);
  }
}
// 相当于老代码的RESTRICT.float
function roundToNDigits(value: number | string, digits: number = 2): number {
  if (typeof value === 'number') {
    value = value.toString();
  }
  if (value === '') return 0;
  const [integer, decimal] = String(value).split('.');
  if (decimal !== undefined && decimal.length > digits) {
    const roundedDecimal = decimal.substr(0, digits);
    return Number(`${integer}.${roundedDecimal}`);
  } else {
    return Number(value);
  }
}
function formatDate(format: string, options: { date?: Date | number; isUTC?: boolean } = {}): string | null {
  let { date, isUTC } = options;

  /***新旧接口兼容 旧接口返回nunber类型 新接口返回string类型 string类型时间戳无法直接转化为时间对象 */
  date = Number(date) || date;
  /** */

  if (!format) return null;

  if (!date) {
    date = new Date();
  } else {
    date = new Date(date);
  }

  let y: string, m: string, d: string, h: string, i: string, s: string;

  if (isUTC) {
    y = date.getFullYear().toString();
    m = completeNum(date.getUTCMonth() + 1).toString();
    d = completeNum(date.getUTCDate()).toString();
    h = completeNum(date.getUTCHours()).toString();
    i = completeNum(date.getUTCMinutes()).toString();
    s = completeNum(date.getUTCSeconds()).toString();
  } else {
    y = date.getFullYear().toString();
    m = completeNum(date.getMonth() + 1).toString();
    d = completeNum(date.getDate()).toString();
    h = completeNum(date.getHours()).toString();
    i = completeNum(date.getMinutes()).toString();
    s = completeNum(date.getSeconds()).toString();
  }

  return format.replace('y', y).replace('m', m).replace('d', d).replace('h', h).replace('i', i).replace('s', s);
}

function completeNum(num: number): string {
  return num < 10 ? '0' + num : num.toString();
}
// 小数向上取精度 @param {boolean} isUp 是否向上取
function formatNumber2Ceil(v: any, fixed: number, isUp = true) {
  const x = Math.pow(10, fixed);
  const next = Number(Number(v).mul(x).toFixed(1));
  return Number((isUp ? Math.ceil : Math.floor)(next).div(x)) + 0 || 0;
}

const formatDefaultText = (text: any, online?: boolean): string => {
  if (!!text == false || ['undefined', 'null', 'NaN'].includes(text) || online === false) {
    return '--';
  }
  return String(text);
};

const Precision = 8;

// 计算结果处理
export const formatCalculationResult = (result: string | number) => {
  let next = Number(result);

  if (Number.isNaN(next)) {
    return 0;
  }
  return Number(next.toRound(Precision)) + 0; // + 0 解决 -0的情况
};

const FCR = formatCalculationResult;

/**
 * 将rgb字符串转成16进制的色值
 */
function formatRgbStringToHex(rgb: string): string {
  const [r, g, b] = rgb.split(',').map(Number);
  // 将数值转换为两位数的十六进制字符串
  const toHex = (n: number) => {
    const hex = n.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  // 使用toHex函数将r, g, b转换为十六进制，然后拼接起来
  return '#' + toHex(r) + toHex(g) + toHex(b);
}
/**
 * formatHexColor2Rgba 进制转rgba
 */
function formatHexColor2Rgba(color: string, opacity: number) {
  var sColor = color.toLowerCase();
  //十六进制颜色值的正则表达式
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = '#';
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    //处理六位的颜色值
    var sColorChange = [];
    for (var i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
    }
    return `rgba(${sColorChange.join(',')},${opacity})`;
  }
  return sColor;
}
function formatSecondsToMinutesAndSeconds(seconds: number) {
  // 计算分钟和秒钟
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds % 60;

  // 格式化成分钟:秒钟的形式
  let formattedTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;

  return formattedTime;
}
/** 将秒钟格式化成带有分钟：秒钟的形式 */
function formatSeconds(seconds: number) {
  if (!seconds) return '--';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}${LANG('分钟')} ${remainingSeconds}${LANG('秒')}`;
}
/** 限定输入数字在指定的scale精度范围内 */
const truncateValueWithScale = (value: string, scale: number) => {
  const [integerPart, decimalPart] = value.split('.');
  if (decimalPart && decimalPart?.length > scale) {
    // 截断小数部分
    const truncatedDecimalPart = decimalPart.substring(0, scale);
    const valueWithScale = integerPart + '.' + truncatedDecimalPart;
    return valueWithScale;
  }
  return value;
};

// 数字小数点全是0则去掉，否则维持不变
function removeTrailingZeros(num: number | string): string {
  // 将数字转换为字符串
  const numStr = num.toString();

  // 检查小数点后的数字是否全都是0
  // 正则表达式解释：\.0+ 表示小数点后跟着一个或多个0
  if (/\.0+$/.test(numStr)) {
    // 去掉小数点后的所有0
    return numStr.replace(/\.0+$/, '');
  }

  // 如果小数点后的数字不全为0，则保持原样
  return numStr;
}
/** 将时间格式化成类似： Sep 30th 10:00 - Oct 14th 20:00 */
function formatToMonthDayTimeRange(startTimestamp: number, endTimestamp: number) {
  dayjs.extend(advancedFormat);
  const startDate = dayjs(startTimestamp);
  const endDate = dayjs(endTimestamp);

  const formattedStart = startDate.format('MMM Do HH:mm'); // e.g., "Sep 30th 10:00"
  const formattedEnd = endDate.format('MMM Do HH:mm'); // e.g., "Oct 14th 20:00"

  return `${formattedStart}-${formattedEnd}`;
}

export { completeNum, formatToMonthDayTimeRange, FCR, formatDate, formatDefaultText, formatHexColor2Rgba, formatNumber2Ceil, formatRgbStringToHex, formatSeconds, formatSecondsToMinutesAndSeconds, formatVolume, removeTrailingZeros, roundToNDigits, truncateToTwoDecimals, truncateValueWithScale };

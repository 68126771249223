/**
 * 品牌蓝色
 */
export const BRAND_BLUE_COLOR = '#1772F8';

/**
 * 品牌蓝色(黄色主题那个颜色)
 */
export const BRAND_BLUE_COLOR_1 = '#ffd30f';

/**
 * 品牌K线蓝色
 */
export const BLUE_KLINE_COLOR = '#2C66D1';

/**
 * 品牌蓝色默认字体颜色
 */
export const BRAND_BLUE_FONT_COLOR = '#fff';

/**
 * 品牌蓝色默认字体颜色（黄色主题那个）
 */
export const BRAND_BLUE_FONT_COLOR_1 = '#141717';

/**
 * 品牌上涨颜色值。韩国红色是上涨，相当于--color-green
 */
export const BRAND_BLUE_UP_COLOR = '#FF374B';

/**
 * 品牌下跌颜色值 。韩国蓝色是下跌，相当于--color-red
 */
export const BRAND_BLUE_DOWN_COLOR = '#2C66D1';
/**
 * 品牌默认主色，黄色
 */
export const BRAND_PRIMARY_COLOR = '#ffd30f';
/**
 * 品牌默认主色，黄色(蓝色)
 */
export const BRAND_PRIMARY_COLOR_1 = '#1772F8';

/**
 * 品牌默认主色rgb，黄色
 */
export const BRAND_PRIMARY_COLOR_RGB = '255,211,15';

/**
 * 品牌默认字体黑色
 */
export const BRAND_PRIMARY_FONT_COLOR = '#141717';

import fs from 'fs';
import path from 'path';
import { LANGUAGE } from '../src/constants';
import { cache, PAGE_ENV } from './cache';
import { defaultLang } from './constants';
import { GetStaticProps, MetaKey } from './types';

/**
 * @param noindex 参数默认为seo 就是seo页面，都索引。一旦页面自己定义了noindex false，就不索引
 */
export const getStaticProps = ({ key = 'index', auth = false, env = PAGE_ENV.PC, robots = true }: GetStaticProps) => {
  return async (context: any) => {
    const locale = context.params?.locale || defaultLang;
    const id = context.params?.id || '';
    const { lang, meta } = await getFile(locale, key);
    cache.lang = lang;
    cache.meta = meta;
    cache.locale = locale;
    cache.locales = Object.keys(LANGUAGE);
    cache.env = env;
    let copyTradingName = '';
    if (key?.includes('copy-trading/user')) {
      const names = require(`./follower/names.json`);
      copyTradingName = names[id] || '';
    }
    return {
      props: {
        locale: cache.locale,
        locales: cache.locales,
        meta: cache.meta,
        env: cache.env,
        auth,
        robots,
        id,
        key1: key,
        copyTradingName,
      },
    };
  };
};
function getFirstSegment(str: string) {
  // 使用正则表达式匹配字符串开始到第一个 '/' 之前的部分
  const match = str.match(/^[^\/]+/);
  return match ? match[0] : str;
}
const getFilePath = (locale: string, subPath: string) => {
  return path.join(process.cwd(), 'public', 'static', 'locales', locale, subPath);
};
const generateLang = (langPath: string, symbol: string) => {
  let string = fs.readFileSync(langPath, 'utf8');
  string = string.replace(`var ${symbol} =`, '');
  const lang = new Function('return ' + string)();
  return lang;
};
const getFile = async (locale: string, key: MetaKey) => {
  // 加载语言文件
  const langPath = getFilePath(locale, `${getFirstSegment(key)}.js`);
  const commonLangPath = getFilePath(locale, 'common-lang.js');
  // const remoteTaskLangPath = getFilePath(locale, 'new-remote-task.js');
  const tradeUiLangPath = getFilePath(locale, 'trade-ui-lang.js');
  const lang = generateLang(langPath, 'appLang');
  // const remoteTaskLang = generateLang(remoteTaskLangPath, 'taskLang');
  const commonLang = generateLang(commonLangPath, 'commonAppLang');
  const tradeUiLang = generateLang(tradeUiLangPath, 'tradeUiLang');
  // 动态导入 meta 文件
  const metaModule = await import(`./meta/${locale}.js`);
  const meta = metaModule.default[key] || {};
  return {
    lang: Object.assign({}, lang, tradeUiLang, commonLang) as { [key: string]: string },
    meta,
  };
};

import { useNativeAPP } from '@/core/hooks';
import dynamic from 'next/dynamic';

const Activity = dynamic(() => import('./activity'), { ssr: false, loading: () => null });

const Index = () => {
  const { isNativeAPP } = useNativeAPP();
  if (isNativeAPP) return null;
  return <Activity />;
};

export default Index;

// TG群链接
export const TELEGRAM: { [key: string]: string } = {
  zh: 'https://t.me/BYDFiEnglish',
  en: 'https://t.me/BYDFiEnglish',
  vi: 'https://t.me/BYDFiVietNamChat',
  id: 'https://t.me/BYDFiIndonesia',
  ru: 'https://t.me/BYDFiRussian',
  // 'ko': 'https://t.me/bydfiKorean',
  ja: 'https://t.me/BYDFiEnglish',
};

// face
export const FACEBOOK: { [key: string]: string } = {
  en: 'https://www.facebook.com/BYDFiOfficial',
  vi: 'https://www.facebook.com/BYDFiVietnam',
  id: 'https://www.facebook.com/BYDFiIndonesia',
};

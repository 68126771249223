// --theme-background-color-light-dark 代表不同主题几级色调

import { ColorMap } from '../global/types';

export const blueColorMap: ColorMap = {
  // 首要主题背景色
  '--theme-primary-bg-color': {
    light: '#dfdfdf',
    dark: '#111111',
  },
  // 次要主题背景色
  '--theme-secondary-bg-color': {
    light: '#ECF1F4',
    dark: '#202023',
  },
  '--theme-secondary-bg-color-1': {
    light: '#CEDCF1',
    dark: '#202023',
  },
  '--theme-secondary-bg-color-2': {
    light: '#ffffff',
    dark: '#313535',
  },
  '--theme-sub-button-bg-3': {
    dark: '#3e4343',
    light: 'linear-gradient(45deg, #F4F8FF, #fff)',
  },
  '--theme-font-color-7': {
    dark: '#ffffff',
    light: '#ffffff',
  },
};

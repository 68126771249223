import { useTheme } from '@/core/hooks';
import { cache } from '@/core/i18n';
import { ConfigProvider } from 'antd';

export const AntdThemeConfigProvider = ({ children }: any) => {
  const { skin } = useTheme();
  const isRtl = cache.locale === 'ar';
  const COLOR_PRIMARY_MAP = {
    blue: '#1772F8',
    primary: '#f8bb37',
  };
  return (
    <ConfigProvider
      direction={isRtl ? 'rtl' : 'ltr'}
      theme={{
        token: { colorPrimary: COLOR_PRIMARY_MAP[skin] },
        components: {
          Checkbox: {
            colorPrimary: 'var(--skin-primary-color)',
            colorBgContainer: 'var(--spec-background-color-2)',
            colorPrimaryBorder: 'var(--skin-primary-color)',
            colorWhite: 'var(--theme-font-color-1-reverse)',
            colorBorder: 'var(--spec-font-color-2)',
            colorPrimaryHover: 'var(--skin-primary-bg-color-opacity-2)',
          },
          Tabs: {
            inkBarColor: 'var(--skin-primary-color)',
            itemSelectedColor: 'var(--theme-font-color-1)',
            itemColor: 'var(--spec-font-color-2)',
            horizontalItemGutter: 35,
            titleFontSize: 16,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
